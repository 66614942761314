
.link:hover{
    color: #5078ff;
    cursor: pointer;
    text-decoration: underline;
}

.c-dhzjXW-idWqJbk-css {
    text-align: left;
}

.linkmain :hover{
text-decoration: underline!important;
}
.c-dhzjXW-ilkBNdM-css .c-dhzjXW img,.c-dhzjXW-ijwreRu-css img{
    /* display: none!important; */
}
.c-dUNmZW {
    background-color: #6e56cf!important;
}

table , table tr{
    /* background: red; */
    /* padding: 20px!important; */
    text-align: left!important;
}
table td a{
    display: -webkit-inline-flex;
}
table td a span{
margin-left: 15px;
    margin-top: 14px;
}
.chainmain img{
    /* display: inline!important; */
}
.linkmain{

}

.css-zipzvv{
        padding: 19px 0px!important;
        border-radius: 13px!important;
            border: 1px solid rgb(175, 172, 172)!important;
                overflow: hidden!important;
            margin-bottom: 60px!important;
}
.hover:hover{
    color: #9d8aea !important;
}



.btton{
    color: #9d8aea !important;
    padding:0px 10px;
      
}

.text-leftsdf img{
    width: 52px;
    height: 52px;
}


@media screen and (max-width: 720px) {
  

}
@media screen and (min-width: 720px) {

}
textarea{
    padding: 5px !important;;
}
/* .c-dhzjXW .c-PJLV .c-dUNmZW-dYhJCg-size-small{
    display: none !important;
} */
.c-dhzjXW .c-PJLV button{
    background-color: #0A2B9A !important;
} 
.mw-400{
    max-width: 600px!important;
}
.rounded-lg, .chakra-container{
    -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        border: none;
}
.headeown{
    position: absolute;
        left: 63px;
        top: 237px;
}

.c-dhzjXW-ilkBNdM-css .c-dhzjXW img,
.c-dhzjXW-ijwreRu-css
.newmaindd img{
    /* display: block!important; */
}

main .d-none22 .c-dhzjXW-ihxfhCv-css , .c-dhzjXW-ilkBNdM-css .innerfloor .c-dhzjXW img, .collactiorank .c-dhzjXW-iTKOFX-direction-column img.c-PJLV{ 
    /* display: none!important; */
}
.custombox{
        padding: 42px;
            border-bottom: 1px solid #2c2929;
            font-size: 2rem;
       
}
.againbox{
        border-bottom: 1px solid #2c2929;
       
        font-size: 24px;
            font-weight: 700;
                padding: 30px 0px 0px 0px
}

.againbox1{
        border-bottom: 1px solid #2c2929;
       
        font-size: 24px;
            font-weight: 700;
               
}
.rk-c-PJLV p.rk-c-kfNQbY-hZSQmm-style-subtitle2{
    /* display: none !important; */
}


/* .rk-c-gMSGwN:nth-child(1) */
/* .rk-c-PJLV .rk-c-gMSGwN-iTKOFX-direction-column .rk-c-gTLXsm div div .rk-c-gMSGwN{
    display:block;
    background: red;
}
.rk-c-PJLV .rk-c-gMSGwN-iTKOFX-direction-column .rk-c-gTLXsm div div .rk-c-gMSGwN div{
    display:block; 
    background: blue;
} */




.rk-c-PJLV .rk-c-gMSGwN-iTKOFX-direction-column .rk-c-gTLXsm div:nth-child(3) div.rk-c-kfNQbY-jmpWBm-color-subtle {
   /* display: none; */
}


 /* .rk-c-PJLV .rk-c-gMSGwN-iTKOFX-direction-column .rk-c-gTLXsm    div:nth-child(5).rk-c-gTLXsm .rk-c-gMSGwN {
    display: none;
} */


/* .rk-c-PJLV .rk-c-gMSGwN-iTKOFX-direction-column .rk-c-gTLXsm    div:nth-child(6) {
    display: none;
} */







.c-dhzjXW .c-PJLV button {
    background-color: #0A2B9A !important;
  
}

.c-dUNmZW {
    background-color: #0A2B9A !important;
}
.tochangemain1{
    padding: 25px!important;
}
.tocollections1 button.c-dUNmZW-jNtXOL-corners-rounded{
background: none!important;
}
.tocollections1 button.c-dUNmZW-dzltuD-color-primary{
background: #0A2B9A!important;
}
.colorlink{
    color: #5078ff !important;
}
/* .increasegab{
    margin:70px 0px;
} */

.css-zipzvv{
    overflow-x: auto!important;
}
.chakra-select__icon-wrapper{
    /* display: none!important; */
}
.whitetheme button, .whitetheme .c-PJLV button{
background-color: var(--colors-gray3) !important;
}
.newtable{
    border: 0px !important;
}


.rk-c-gUGjQi[data-state="open"] .rk-c-gMSGwN a{
    /* display: none!important; */
} 

/* .c-dhzjXW .c-ySlMJ[data-state="checked"] {
     background-color: var(--colors-primary9)!important; 
} */



.c-dhzjXW .c-ySlMJ[data-state="unchecked"] {
    background-color: #454153 !important;
}
/* .fa-sun{
    color: #cfac0c!important; 
}

.fa-cart-shopping{
     color: #000!important; 
} */
/* //for white theme */
 body.rk-t-fBETYI tr:hover{
    background-color: rgb(220 222 224)!important;
}

body.rk-t-fBETYI input,
body.rk-t-fBETYI optgroup,
body.rk-t-fBETYI select,
body.rk-t-fBETYI textarea {
background-color: #f1f3f5 !important;
}

body.rk-t-fBETYI button span{
    color: rgb(220 222 224)!important;
}
/*.c-dUNmZW-klnldd-color-gray3 {
    color: hsl(0deg 0% 100%)!important;
}*/
/* black theme start */
body.rk-t-jzAEpe .c-kzXVuW {
    color: hsl(0deg 0% 100%)!important;

} 
body.rk-t-jzAEpe .c-cAoCpJ[data-state="on"] {
    background-color: #0A2B9A !important;
    color: white !important;
}

/* //////////////////teamp//////////////// */

   /* div:has(>img[src="https://api-goerli.reservoir.tools/redirect/sources/looksrare/logo/v2"]) {
  display: none!important;
   
} */
/* .rk-c-gTLXsm div[class="rk-c-gMSGwN rk-c-gMSGwN-jroWjL-align-center"]{
    display: none !important;
} */

/* div[class="rk-c-gMSGwN rk-c-gMSGwN-jroWjL-align-center"]:has(>span[class="rk-c-kfNQbY rk-c-kfNQbY-jmpWBm-color-subtle rk-c-kfNQbY-hZSQmm-style-subtitle2 rk-c-kfNQbY-ijkHsGW-css"]){
    display: none!important;
} */
div[class="rk-c-gMSGwN rk-c-gMSGwN-jroWjL-align-center rk-c-gMSGwN-igkoUjS-css"], div[class="rk-c-gMSGwN rk-c-gMSGwN-jroWjL-align-center rk-c-gMSGwN-ikfJGxR-css"]{
    display: none!important;
}

/* //////////////////teamp//////////////// */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ///////////////radix ui////////////////// */


@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */


.DialogOverlay {
    background-color: var(--black-a9);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
    z-index: 99;
    background-color: black;
    color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 450px;
    max-height: 85vh;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent:focus {
    outline: none;
}

.DialogTitle {
    margin: 0;
    font-weight: 500;
    color: #fff;
    font-size: 17px;
}

.DialogDescription {
    margin: 10px 0 20px;
    color: var(--mauve-11);
    font-size: 15px;
    line-height: 1.5;
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
}

.Button.violet {
    background-color: white;
    color: var(--violet-11);
    box-shadow: 0 2px 10px var(--black-a7);
}

.Button.violet:hover {
    background-color: var(--mauve-3);
}

.Button.violet:focus {
    box-shadow: 0 0 0 2px black;
}

.Button.green {
    background-color: var(--green-4);
    color: var(--green-11);
}

.Button.green:hover {
    background-color: var(--green-5);
}

.Button.green:focus {
    box-shadow: 0 0 0 2px var(--green-7);
}

.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--violet-11);
    position: absolute;
    top: 10px;
    right: 10px;
}

.IconButton:hover {
    background-color: var(--violet-4);
}

.IconButton:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
}

.Fieldset {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 15px;
}

.Label {
    font-size: 15px;
    color: #fbfbfb;
    width: 90px;
    text-align: right;
}

.Input {
    width: 100%;
    /* flex: 1; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 15px;
    line-height: 1;
    color: #fbfbfb;
    box-shadow: 0 0 0 1px var(--violet-7);
    height: 35px;
}

/* .Input:focus {
    box-shadow: 0 0 0 2px var(--violet-8);
} */

@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}




.example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#myGrid {
    flex: 1 1 0px;
    width: 100%;
}
.ag-cell span{

display: flex;
}
.ag-cell span img{
    width: 35px;
    border-radius: 8px;
    display: 'flex';
    padding: 5px;
}
.ag-theme-alpine, .ag-theme-quartz, .ag-theme-alpine-dark{
    text-align: -webkit-center;
}

.ag-center-cols-container{
    /* width: 100%!important; */
}
.ag-cell{
    text-align: left!important;

}
div[col-id="twentyfourHourMint"] img{

    /* width: 18px;
     
        height: 21px;
       
        margin-top: 10px; */


}


div[col-id="1"] img {

    /* width: 22px;

    height: 26px;

    margin-top: 8px; */


}

.ag-theme-alpine-dark {
    --ag-background-color: #191d1f00!important;
}

.againbox1 button{
    margin-bottom: 5px;
}

.fortabless table thead{

}

.droptable thead{
border-bottom: 1px solid #7c8387;
}
.droptable tr {
height: 77px;
border-bottom: 1px solid #444647;
}
.drownmints{
    background:#0A2B9A !important;
        padding: 11px 13px;
            padding-bottom: 9px;
            border-radius: 8px;
            margin-left: 10px;
}
.toshortimage  span{
    display: -webkit-inline-box;
}
.toshortimage  span img{
    width: 21px;
    height: 21px;
    margin-top: 11px;
    margin-left: 4px;
}
.toshortimage1  span img{
    width: 18px;
    height: 18px;
    margin-top: 13px;
    margin-left: 4px;
}

#myGrid {
    flex: 1 1 0px;
    width: 100%;
}

.example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.grid-wrapper {
    flex: 1 1 0px;
    width: 100%;
}

#myGrid {
    height: 100%;
}
.ag-center-cols-container{
width:100%;
}

.imagebg{
    width: 100%!important;
    height: 100%!important;
    position: absolute;
    top: 0;
}

.show{
    display: none;
}
@media screen and (min-width: 1000px) {
.hide {
        display: none;
    }
    .show{
        display: block;
    }
}


.c-dhzjXW .c-PJLV button.readmore{
    background: unset;
        background-color: unset !important;
        color: #536ab9 !important;
 
}
/* .c-dhzjXW .c-PJLV button[role="switch"]{
    color: grey !important;
} */

.c-dhzjXW .c-PJLV button.c-ySlMJ[data-state="checked"] {
    background-color: #0A2B9A !important;
}

textarea{
    color: white !important;;
    height: unset !important;
}

img,
video {
    max-width: unset;
}

.footer{
    padding: 0px 20px;
    padding-top: 10px;
}


#grid-wrapper, .grid-wrapper{
        z-index: 1;
    display: block;
    position: relative;
}
 select {

    background: #222628!important;
    border: 5px!important;
   
}
/* select.selector{
color:blue;
} */







